import React from 'react'
import { Link } from 'react-router-dom'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from '../../gsap/SplitText'

import bootstrapService from '../../services/BootstrapService'
import paymentService from '../../services/PaymentService'

import Navbar from '../shared/Navbar/Navbar'
import CourseInfo from '../shared/CourseInfo/CourseInfo'
import QuickBuy from './components/QuickBuy/QuickBuy'
import ReadMoreButton from './components/ReadMoreButton/ReadMoreButton'
import CourseObjective from './components/CourseObjective/CourseObjective'
import Calendar from '../shared/Calendar/Calendar'
import BuyCourseCard from './components/BuyCourseCard/BuyCourseCard'
import BuyMembershipCard from './components/BuyMembershipCard/BuyMembershipCard'
import BuyBundleCard from './components/BuyBundleCard/BuyBundleCard'
import AllBuyOptionsButton from './components/AllBuyOptionsButton/AllBuyOptionsButton'
import Testimonials from '../shared/Testimonials/Testimonials'
import Subscribe from '../shared/Subscribe/Subscribe'
import Footer from '../shared/Footer/Footer'

import numberImage from './images/number.svg'
import deliveryImage from './images/delivery.svg'
import dateImage from './images/calendar.svg'
import creditsImage from './images/clock.svg'

import styles from './CourseDetails.module.css'

class CourseDetails extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.category = null;
        this.title = null;
        this.authors = [];
        this.bios = [];
        this.infos = [];
        this.image = null;
        this.quickBuys = [];
        this.descriptionContainer = null;
        this.description = null;
        this.readMoreButton = null;
        this.objectivesTitle = null;
        this.sectionTitles = [];
        this.objectives = [];
        this.calendarTitle = null;
        this.calendar = null;
        this.buyCourse = null;
        this.buyMemberships = [];
        this.buyBundles = [];
        this.allBuy = null;

        // Vars
        this.descriptionHeight = null;

        // State
        this.state = {
            isDescriptionOpen: false,
            schedule: null
        }

        // GSAP
        gsap.registerPlugin(ScrollTrigger);
        gsap.registerPlugin(SplitText);

        // This Binding
        this.clickReadMore = this.clickReadMore.bind(this);
        this.goToBuy = this.goToBuy.bind(this);
        this.goToMemberships = this.goToMemberships.bind(this);
        this.goToBundles = this.goToBundles.bind(this);
        this.animateQuickBuyEnter = this.animateQuickBuyEnter.bind(this);
        this.animateQuickBuyLeave = this.animateQuickBuyLeave.bind(this);
        this.animateButtonEnter = this.animateButtonEnter.bind(this);
        this.animateButtonLeave = this.animateButtonLeave.bind(this);
        this.selectSchedule = this.selectSchedule.bind(this);
        this.showAuthorBio = this.showAuthorBio.bind(this);
        this.hideAuthorBio = this.hideAuthorBio.bind(this);
    }

    // Lifecycle
    componentDidMount() {
        setTimeout(() => {
            this.refreshScrollTrigger();
            this.setupScrollBarWidth();
        }, 0);

        window.scrollTo(0, 0);
        this.setupReadMoreButton();
        this.setupAnimations();
        // this.setupAuthorBios();
    }

    // Helpers
    refreshScrollTrigger() {
        ScrollTrigger.refresh();
    }

    setupScrollBarWidth() {
        document.documentElement.style.setProperty('--scrollbar-width', (window.innerWidth - document.documentElement.clientWidth) + "px");
    }

    setupReadMoreButton() {
        // Timeout hack ensures function runs after Render (so that REFs are set)
        setTimeout(() => {
            if (this.description) {
                const style = getComputedStyle(this.description);
                // console.log("description = " + parseInt(style.height))
                const maxHeight = window.innerWidth >= 1600 ? 225 : 200;
                if (parseInt(style.height) < maxHeight) {
                    this.readMoreButton.style.display = "none";
                    this.description.classList.add(styles['short-description']);
                }
                else {
                    this.descriptionHeight = style.height;
                    this.description.style.maxHeight = `${maxHeight}px`;
                }

                ScrollTrigger.refresh();
            }
        }, 0);
    }

    setupAnimations() {
        gsap.from(this.category, {
            duration: 0.5,
            delay: 0.3,
            y: 25,
            opacity: 0,
        });

        let split = new SplitText(this.title, { type: 'lines' });
        gsap.from(split.lines, {
            duration: 0.5,
            delay: 0.55,
            stagger: 0.15,
            y: 25,
            opacity: 0,
        });

        gsap.from([...this.authors, ...this.infos], {
            duration: 0.5,
            delay: 1,
            stagger: 0.25,
            y: 25,
            opacity: 0,
        });

        gsap.from(this.image, {
            duration: 0.5,
            stagger: 0.25,
            delay: 0.8,
            y: 25,
            opacity: 0,
        });

        gsap.from(this.quickBuys, {
            duration: 0.5,
            stagger: 0.25,
            delay: 1.3,
            x: 10,
            opacity: 0,
        });

        gsap.from(this.descriptionContainer, {
            scrollTrigger: {
                trigger: this.descriptionContainer,
                start: 'top 90%',
            },
            duration: 0.5,
            y: 25,
            opacity: 0,
        });

        gsap.from(this.objectivesTitle, {
            scrollTrigger: {
                trigger: this.objectivesTitle,
                start: 'top 90%',
            },
            duration: 0.5,
            y: 25,
            opacity: 0,
        });

        for (const title of this.sectionTitles) {
            gsap.from(title, {
                scrollTrigger: {
                    trigger: title,
                    start: 'top 90%',
                },
                duration: 0.5,
                y: 25,
                opacity: 0,
            });
        }

        for (const objective of this.objectives) {
            gsap.from(objective, {
                scrollTrigger: {
                    trigger: objective,
                    start: 'top 90%',
                },
                duration: 0.5,
                y: 25,
                opacity: 0,
            });
        }

        if (this.calendarTitle) {
            gsap.from(this.calendarTitle, {
                scrollTrigger: {
                    trigger: this.calendarTitle,
                    start: 'top 90%',
                },
                duration: 0.5,
                y: 25,
                opacity: 0,
            });
        }

        if (this.calendar) {
            gsap.from(this.calendar, {
                scrollTrigger: {
                    trigger: this.calendar,
                    start: 'top 90%',
                },
                duration: 0.5,
                y: 25,
                opacity: 0,
            });
        }

        if (this.buyCourse) {
            gsap.from(this.buyCourse, {
                scrollTrigger: {
                    trigger: this.buyCourse,
                    start: 'top 90%',
                },
                duration: 0.5,
                y: 25,
                opacity: 0,
            });
        }

        for (const membership of this.buyMemberships) {
            gsap.from(membership, {
                scrollTrigger: {
                    trigger: membership,
                    start: 'top 90%',
                },
                duration: 0.5,
                y: 25,
                opacity: 0,
            });
        }

        for (const bundle of this.buyBundles) {
            gsap.from(bundle, {
                scrollTrigger: {
                    trigger: bundle,
                    start: 'top 90%',
                },
                duration: 0.5,
                y: 25,
                opacity: 0,
            });
        }

        gsap.from(this.allBuy, {
            scrollTrigger: {
                trigger: this.allBuy,
                start: 'top 90%',
            },
            duration: 0.5,
            y: 25,
            opacity: 0,
        });
    }

    setupAuthorBios() {
        for (const [index, bio] of this.bios.entries()) {
            const rect = this.authors[index].getBoundingClientRect();
            bio.style.top = `${rect.top + rect.height + window.scrollY - 5}px`; 
        }
    }

    // Event Hanlders
    clickReadMore(e) {
        e.preventDefault();

        this.setState({ isDescriptionOpen: !this.state.isDescriptionOpen }, () => {
            if (this.state.isDescriptionOpen) {
                gsap.to(this.description, {
                    duration: 0.3,
                    maxHeight: this.descriptionHeight,
                    onComplete: this.refreshScrollTrigger,
                })
            }
            else {
                gsap.to(this.description, {
                    duration: 0.3,
                    maxHeight: window.innerWidth >= 1600 ? 225 : 200,
                    onComplete: this.refreshScrollTrigger,
                })
            }
        });
    }

    goToBuy(item, type) {
        paymentService.reset();
        paymentService.item = { ...item, quantity: 1 };
        paymentService.itemType = type;

        if (this.state.schedule) {
            paymentService.schedule = this.state.schedule;
        }

        this.props.history.push('/buy-register');
    }

    goToMemberships(item) {
        const memberships = bootstrapService.data.passTypes;
        if (memberships.length > 1) {
            this.buyMemberships[0].scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        else {
            this.goToBuy(item, 'membership');
        }
    }

    goToBundles(item) {
        const course = bootstrapService.getCourse(this.props.match.params.id);
        const bundles = this.getBundles(course.id);
        if (bundles.length > 1) {
            this.buyBundles[0].scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        else {
            this.goToBuy(item, 'bundle');
        }
    }

    selectSchedule(schedule) {
        this.setState({ schedule: schedule });
    }

    animateQuickBuyEnter(index) {
        gsap.to(this.quickBuys[index], {
            translateX: 5,
        });
    }

    animateQuickBuyLeave(index) {
        gsap.to(this.quickBuys[index], {
            translateX: 0,
        });
    }

    animateButtonEnter(button) {
        gsap.to(button, {
            scale: 1.03,
        });
    }

    animateButtonLeave(button) {
        gsap.to(button, {
            scale: 1,
        });
    }

    showAuthorBio(authorIndex) {
        const rect = this.authors[authorIndex].getBoundingClientRect();
        const bio = this.bios[authorIndex];
        const margin = window.innerWidth >= 768 ? (window.innerWidth >= 1200 ? 20 : 15) : 10;
        bio.style.top = `${rect.top + rect.height + window.scrollY + margin}px`; 
        bio.style.opacity = 1;
        bio.style.visibility = 'visible'
    }

    hideAuthorBio(authorIndex) {
        const bio = this.bios[authorIndex];
        bio.style.opacity = 0;
        bio.style.visibility = 'hidden';
    }

    // Data
    getBundles(courseId) {
        const bundles = bootstrapService.data.courseBundleTypes;
        if (bundles) {
            let bundlesWithCourse = [];
            bundles.forEach((bundle, index) => {
                if (bundle.courseDigests && bundle.courseDigests.filter(c => c.id === courseId).length > 0) {
                    bundlesWithCourse.push(bundle);
                }
            })
            return bundlesWithCourse.length > 0 ? bundlesWithCourse : null;
        }
    }

    // Component
    render() {
        const course = bootstrapService.getCourse(this.props.match.params.id);

        if (!course) {
            this.props.history.push('/courses');
        }

        const releaseDate = course ? new Date(course.releaseDate).toDateString().split(' ').slice(1).join(' ') : null;
        const memberships = bootstrapService.data.passTypes;
        const bundles = this.getBundles(course.id);
        const showReleaseDate = bootstrapService.getShowReleaseDate() && releaseDate;

        const schedule = this.state.schedule ? new Date(this.state.schedule.startDate).toLocaleDateString("en-US", {weekday: 'long', month: 'long', day: 'numeric'}) : null;

        this.infos = [];  // Reset Ref Array
        this.quickBuys = []; // Reset Ref Array
        this.quickBuyCount = 0;

        return (
            <>
                <Navbar />
                <div className={styles['background']}>
                    <section className={styles['content']}>
                        <div className={styles['upper-container']}>
                            <div className={styles['info-container']}>
                                <span className={styles['category']} ref={ref => this.category = ref}>{course.subjectArea}</span>
                                <h1 className={styles['title']} ref={ref => this.title = ref}>{course.title}</h1>
                                <div className={styles['authors']}>
                                    {
                                        course.authors &&
                                        course.authors.map((author, authorIndex) => {
                                            return <div className={styles['author']} ref={ref => this.authors[authorIndex] = ref} key={authorIndex}>
                                                <span>By&nbsp;</span>
                                                <span className={styles['author-name']} onMouseEnter={(e) => this.showAuthorBio(authorIndex)} onMouseLeave={(e) => this.hideAuthorBio(authorIndex)}>{author.name}</span>
                                                <span>&nbsp;{author.designations}</span>
                                            </div>
                                        })
                                    }
                                </div>
                                {
                                    course.authors &&
                                    course.authors.map((author, authorIndex) => {
                                        return author.bio && <span className={styles['author-bio']} dangerouslySetInnerHTML={{ __html: author.bio }} ref={ref => this.bios[authorIndex] = ref} key={authorIndex}></span>      
                                    })
                                }
                                <div className={styles['info-items']}>
                                    {
                                        showReleaseDate &&
                                        <CourseInfo image={numberImage} property='Course No' value={course.courseNo} ref={ref => { if (ref !== null) this.infos.push(ref) }} />
                                    }
                                    <CourseInfo image={deliveryImage} property='Delivery Method' value={course.deliveryMethod} ref={ref => this.infos.push(ref)} />
                                    {
                                        showReleaseDate &&
                                        <CourseInfo image={dateImage} property='Publish Date' value={releaseDate} ref={ref => { if (ref !== null) this.infos.push(ref) }} />
                                    }
                                    <CourseInfo image={creditsImage} property={bootstrapService.getCreditsTerm(course.credits)} value={course.credits.toFixed(1)} ref={ref => this.infos.push(ref)} />
                                </div>
                            </div>
                            <div className={styles['image-container']}>
                                <img src={course.imageUrl} alt='' className={styles['image']} ref={ref => this.image = ref} />
                                <div className={styles['buy-items']}>
                                    {
                                        course.item && // arrow function inside onClick is required to pass argument
                                        <QuickBuy title='Buy Course' price={course.item.price} onClick={() => this.goToBuy(course.item, 'course')} ref={ref => { if (ref !== null) this.quickBuys.push(ref) }} index={this.quickBuyCount++} onMouseEnter={(index) => this.animateQuickBuyEnter(index)} onMouseLeave={(index) => this.animateQuickBuyLeave(index)} />
                                    }
                                    {
                                        memberships && // arrow function inside onClick is required to pass argument
                                        <QuickBuy title='Membership' price={memberships[0].item.price} onClick={() => this.goToMemberships(memberships[0].item)} ref={ref => { if (ref !== null) this.quickBuys.push(ref) }} index={this.quickBuyCount++} onMouseEnter={(index) => this.animateQuickBuyEnter(index)} onMouseLeave={(index) => this.animateQuickBuyLeave(index)} />
                                    }
                                    {
                                        bundles && // arrow function inside onClick is required to pass argument
                                        <QuickBuy title='Course Bundle' price={bundles[0].item.price} onClick={() => this.goToBundles(bundles[0].item)} ref={ref => { if (ref !== null) this.quickBuys.push(ref) }} index={this.quickBuyCount++} onMouseEnter={(index) => this.animateQuickBuyEnter(index)} onMouseLeave={(index) => this.animateQuickBuyLeave(index)} />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={styles['description-container']} ref={ref => this.descriptionContainer = ref}>
                            <span className={styles['description-title']}>Overview</span>
                            <p className={`${styles['description']} ${styles['truncate']}`} ref={ref => this.description = ref} dangerouslySetInnerHTML={{ __html: course.description }}></p>
                            <ReadMoreButton isOpen={this.state.isDescriptionOpen} className={styles['read-more']} ref={ref => this.readMoreButton = ref} onClick={this.clickReadMore} />
                        </div>
                        <span className={styles['objectives-title']} ref={ref => this.objectivesTitle = ref}>Learning Objectives</span>
                        {
                            course.sections &&
                            course.sections.map((section, sectionIndex) => {
                                return <React.Fragment key={sectionIndex} >
                                    <h2 className={styles['objectives-section']} ref={ref => this.sectionTitles[sectionIndex] = ref}>{course.sections.length > 1 ? section.title : course.title}</h2>
                                    <ol className={styles['objectives']}>
                                        {
                                            section.objectives &&
                                            section.objectives.map((objective, objectiveIndex) => {
                                                return <li key={objectiveIndex}><CourseObjective data={objective} ref={ref => this.objectives[objectiveIndex] = ref} /></li>
                                            })
                                        }
                                    </ol>
                                </React.Fragment>
                            })
                        }
                        {
                            course.schedules &&
                            <div className={styles['schedules']}>
                                <h2 className={styles['calendar-title']} ref={ref => this.calendarTitle = ref}>Dates & Times</h2>
                                <Calendar schedules={course.schedules} selectSchedule={this.selectSchedule} onBuy={() => this.goToBuy(course.item, 'course')} ref={ref => this.calendar = ref} />
                            </div>
                        }
                        <div className={styles['pricing']}>
                            {
                                course.item &&
                                <BuyCourseCard data={course} schedule={schedule} onClick={() => this.goToBuy(course.item, 'course')} ref={ref => this.buyCourse = ref} />
                            }
                            {
                                memberships &&
                                memberships.map((membership, membershipIndex) => {
                                    return <BuyMembershipCard data={membership} key={membershipIndex} onClick={() => this.goToBuy(membership.item, 'membership')} ref={ref => this.buyMemberships[membershipIndex] = ref} />
                                })
                            }
                            {
                                bundles &&
                                bundles.map((bundle, bundleIndex) => {
                                    return <BuyBundleCard data={bundle} key={bundleIndex} onClick={() => this.goToBuy(bundle.item, 'bundle')} ref={ref => this.buyBundles[bundleIndex] = ref} />
                                })
                            }
                            <Link to={`/pricing/${bootstrapService.defaultPlanType}`}><AllBuyOptionsButton className={styles['all-buy']} ref={ref => this.allBuy = ref} onMouseEnter={(e) => this.animateButtonEnter(this.allBuy)} onMouseLeave={(e) => this.animateButtonLeave(this.allBuy)} /></Link>
                        </div>
                    </section>
                </div>
                <Testimonials className={styles['testimonials']} />
                <Subscribe />
                <Footer />
            </>
        )
    }
}

export default CourseDetails;