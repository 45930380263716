import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import gsap from 'gsap'

import formService from '../../services/FormService'
import memberService from '../../services/MemberService'
import recaptchaService from '../../services/RecaptchaService'

import PrimaryButton from '../shared/Buttons/PrimaryButton/PrimaryButton'
import Input from '../shared/Input/Input'

import styles from './ForgotPassword.module.css'

class ForgotPassword extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.container = null;
        this.form = null;
        this.success = null;
        this.message = null;

        // State
        this.state = {
            isFormLoading: false,
        }

        // This Binding
        this.submitForm = this.submitForm.bind(this);
    }

    // Lifecycle
    componentDidMount() {
        this.setupAnimations();
    }

    // Helpers
    setupAnimations() {
        gsap.from(this.container, {
            duration: 0.5,
            opacity: 0,
        })
    }

    showSuccess(message) {
        this.message.innerText = message;
        gsap.to(this.success, {
            duration: 0.5,
            opacity: 1,
            visibility: 'visible'
        })
    }

    hideSuccess() {
        gsap.to(this.success, {
            duration: 0.3,
            opacity: 0,
            onComplete: () => {
                gsap.set(this.success, {
                    visibility: 'hidden',
                })
            }
        })
    }

    // Event Handlers
    submitForm(e) {
        e.preventDefault();

        this.hideSuccess();

        const isFormValid = formService.validate(this.form);
        if(!isFormValid) return;

        this.setState({ isFormLoading: true });

        const email = e.target.email.value.trim();

        let forgotPasswordRequest = { email };

        recaptchaService.getToken("forgotPassword")
        .then(token => {
            forgotPasswordRequest.recaptchaToken = token;

            memberService.forgotPassword(forgotPasswordRequest)
            .then(json => {
                if (json.succeeded) {
                    // Success
                    this.setState({ isFormLoading: false });
                    this.showSuccess("We’ve emailed you a link to reset your password. If you don't see it, please check your spam folder before getting in touch!")
                }
                else {
                    this.setState({ isFormLoading: false });
                    this.showSuccess("If there is an account with your email address then we have emailed you a link to reset your password. If you don’t see it, please check your spam folder before getting in touch!");
                }
            });
        });
    }

    // Component
    render() {
        return (
            <div className={styles['form-container']} ref={ref => this.container = ref}>
                <h1 className={styles['title']}>Forgot Password</h1>
                <form className={styles['form']} ref={ref => this.form = ref} onSubmit={this.submitForm}>
                    <Input className={styles['email-input']} type='email' name='email' placeholder='E-mail' required />
                    <PrimaryButton loading={this.state.isFormLoading} whiteSpinner={true} noAnimate={true} scale={1.02} className={styles['submit-button']} type='submit' formNoValidate>Send me a reset link</PrimaryButton>
                    <div className={styles['success-container']} ref={ref => this.success = ref}>
                        <p className={styles['success']} ref={ref => this.message = ref}>We’ve emailed you a link to reset your password. If you don’t see it, please check your spam folder before getting in touch!</p>
                    </div>
                </form>
                <div className={styles['login-container']}>
                    <span className={styles['login-text']}>Already have an Account?</span>
                    <Link to='/login' replace><span className={styles['login-button']}>Login</span></Link>
                </div>
            </div>
        )
    }
}

export default withRouter(ForgotPassword);