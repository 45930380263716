import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import gsap from 'gsap'

import formService from '../../services/FormService'
import memberService from '../../services/MemberService'
import recaptchaService from '../../services/RecaptchaService'
import { MemberType } from '../../services/MemberService'

import PrimaryButton from '../shared/Buttons/PrimaryButton/PrimaryButton'
import Input from '../shared/Input/Input'
import FullNameInput from '../shared/FullNameInput/FullNameInput'
import Toast from '../shared/Toast/Toast'

import styles from './Register.module.css'

class Register extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.container = null;
        this.form = null;
        this.toast = null;

        // State
        this.state = {
            isFormLoading: false,
            useMiddleName: false,
            toastMessage: null,
        }

        // This Binding
        this.useMiddleName = this.useMiddleName.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.exitToast = this.exitToast.bind(this);
    }

    // Lifecycle
    componentDidMount() {
        this.setupAnimations();
    }

    // Helpers
    setupAnimations() {
        gsap.from(this.container, {
            duration: 0.5,
            opacity: 0,
        })
    }

    // Event Handlers
    submitForm(e) {
        e.preventDefault();

        this.exitToast();

        const isFormValid = formService.validate(this.form);

        const email = e.target.email.value.trim();
        const password = e.target.password.value;
        const confirm = e.target.confirm.value;

        let firstName = "";
        let middleName = "";
        let lastName = "";

        if (this.state.useMiddleName) {
            firstName = e.target.firstName.value;
            middleName = e.target.middleName.value;
            lastName = e.target.lastName.value;
        }
        else {
            firstName = e.target.fullName.value.split(" ")[0];
            lastName = e.target.fullName.value.split(" ")[1];
        }

        if (password !== confirm) {
            formService.showInputError(this.form, "confirm", "This password does not match");
            return;
        }

        if(!isFormValid) return;

        this.setState({ isFormLoading: true });

        let memberRequest = {email, password, firstName, middleName, lastName};

        // Check if Joining Group
        if (this.props.match.params.group) {
            memberRequest.groupRegisterCode = this.props.match.params.group;
        }

        recaptchaService.getToken("register")
        .then(token => {
            memberRequest.recaptchaToken = token;
            memberRequest.memberType = MemberType.Register;

            memberService.checkEmailInUse(memberRequest)
            .then((isInUse) =>  {
                if (isInUse) {
                    this.setState({ isFormLoading: false });
                    formService.hideAllErrors(this.form);
                    formService.showInputError(this.form, "email", "This email address is already taken");
                }
                else {
                    memberService.register(memberRequest)
                    .then(json => {
                        if (json.succeeded) {
                            memberService.goToMembers();
                        }
                        else {
                            this.setState({ isFormLoading: false, toastMessage: json.errors[0] });
                            formService.hideAllErrors(this.form);
                            gsap.to(this.toast, {
                                duration: 0.5,
                                visibility: 'visible',
                                opacity: 1,
                                y: -25,
                            })
                        }
                    });
                }
            })
        });
    }

    exitToast() {
        gsap.to(this.toast, {
            duration: 0.3,
            opacity: 0,
            y: 25,
            onComplete: () => {
                gsap.set(this.toast, {
                    visibility: 'hidden'
                });
            },
        })
    }

    useMiddleName() {
        this.setState({ useMiddleName: true });
    }

    // Component
    render() {
        return (
            <div className={styles['form-container']} ref={ref => this.container = ref}>
                <h1 className={styles['title']}>Sign Up</h1>
                <form className={styles['form']} ref={ref => this.form = ref} onSubmit={this.submitForm}>
                    <FullNameInput className={styles['name-input']} type='text' placeholder='Full Name' useMiddleName={this.useMiddleName} required />
                    <Input className={styles['email-input']} type='email' name='email' placeholder='E-mail' required />
                    <Input className={styles['password-input']} type='password' name='password' placeholder='Password' required />
                    <Input className={styles['confirm-input']} type='password' name='confirm' placeholder='Confirm Password' required />
                    <PrimaryButton loading={this.state.isFormLoading} whiteSpinner={true} noAnimate={true} scale={1.02} className={styles['register-button']} type='submit' formNoValidate>Create an account</PrimaryButton>
                </form>
                <div className={styles['login-container']}>
                    <span className={styles['login-text']}>Already have an account?</span>
                    <Link to='/login' replace><span className={styles['login-button']}>Login</span></Link>
                </div>
                <Toast className={styles['toast']} error={true} title='Error' description={this.state.toastMessage} onExit={this.exitToast} ref={ref => this.toast = ref} />
            </div>
        )
    }
}

export default withRouter(Register);