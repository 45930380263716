import React from 'react'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from '../../../gsap/SplitText'

import bootstrapService from '../../../services/BootstrapService'
import formService from '../../../services/FormService'
import memberService from '../../../services/MemberService'
import recaptchaService from '../../../services/RecaptchaService'

import { ReactComponent as Arrow } from './images/arrow.svg';
import Spinner from '../Spinner/Spinner'
import Toast from '../Toast/Toast'

import styles from './Subscribe.module.css'

class Subscribe extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.content = null
        this.title = null;
        this.form = null;
        this.input = null;
        this.button = null;
        this.toast = null;

        // State
        this.state = {
            isFormLoading: false,
            isFormError: false,
            toastMessage: null,
        }

        // GSAP
        gsap.registerPlugin(ScrollTrigger);
        gsap.registerPlugin(SplitText);

        // This Binding
        this.clickInput= this.clickInput.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.animateButtonEnter = this.animateButtonEnter.bind(this);
        this.animateButtonLeave = this.animateButtonLeave.bind(this);
        this.exitToast = this.exitToast.bind(this);
    }

    // Lifecycle
    componentDidMount() {
        setTimeout(() => {
            ScrollTrigger.refresh();
        }, 0);

        this.setBackgroundColor();
        this.setupAnimations();
    }

    // Helpers
    setupAnimations() {
        var split = new SplitText(this.title, {type: 'lines'});
        gsap.from(split.lines, {
            scrollTrigger: {
                trigger: this.title,
                start: 'top 90%',
            },
            duration: 0.5,
            stagger: 0.15,
            y: 25,
            opacity: 0,
        });
    }

    // Event Handlers
    clickInput() {
        this.input.focus();
    }

    submitForm(e) {
        e.preventDefault();

        this.exitToast();

        const isFormValid = formService.validate(this.form);
        if(!isFormValid) return;

        this.setState({ isFormLoading: true });

        const email = e.target.email.value.trim();

        recaptchaService.getToken("subscribe")
        .then(token => {
            let subscribeRequirement = {email, recaptchaToken: token};

            memberService.subscribe(subscribeRequirement)
            .then((json) => {
                if (json.succeeded) {
                    // Success
                    this.setState({ isFormLoading: false, isFormError: false, toastMessage: 'Your subscription has been successfully issued.' });
                    formService.hideAllErrors(this.form);
                    e.target.email.value = '';
                    e.target.email.blur();
                    gsap.to(this.toast, {
                        duration: 0.5,
                        visibility: 'visible',
                        opacity: 1,
                        y: -25,
                    })
                }
                else {
                    this.setState({ isFormLoading: false, isFormError: true, toastMessage: json.errors[0] });
                    formService.hideAllErrors(this.form);
                    gsap.to(this.toast, {
                        duration: 0.5,
                        visibility: 'visible',
                        opacity: 1,
                        y: -25,
                    })
                }
            });
        });
    }

    exitToast() {
        gsap.to(this.toast, {
            duration: 0.3,
            opacity: 0,
            y: 25,
            onComplete: () => {
                gsap.set(this.toast, {
                    visibility: 'hidden'
                });
            },
        })
    }

    // Helpers
    setBackgroundColor() {
        this.content.style.backgroundColor = this.props.backgroundColor ? this.props.backgroundColor : 'transparent';
    }

    // Event Handlers
    animateButtonEnter() {
        gsap.to(this.button, {
            duration: 0.3,
            scale: 1.03,
        })
    }

    animateButtonLeave() {
        gsap.to(this.button, {
            duration: 0.3,
            scale: 1,
        })
    }

    // Component
    render() {
        const data = bootstrapService.data.landing.sections.find(s => s.name === "newsletter");
        const header = data ? data.header : null;

        return (
            <section className={`${styles['content']} ${this.props.className}`} ref={ref => this.content = ref}>
                <div className={styles['card']}>
                    <h1 className={styles['title']} ref={ref => this.title = ref}>{header}</h1>
                    <form className={styles['form']} ref={ref => this.form = ref} onSubmit={this.submitForm}>
                        <div className={`${styles['input-container']} a4-input`} onClick={this.clickInput}>
                            <input className={`${styles['input']}`} ref={ref => this.input = ref} type='email' name='email' placeholder='E-mail' required />
                            <span className={`${styles['error']} a4-input-error`}>This is an error</span>
                            <button className={styles['button']} type='submit' formNoValidate ref={ref => this.button = ref} onMouseEnter={this.animateButtonEnter} onMouseLeave={this.animateButtonLeave}>
                                {
                                    this.state.isFormLoading &&
                                    <Spinner white={true} className={styles['spinner']} />
                                }
                                {
                                    !this.state.isFormLoading &&
                                    <Arrow />
                                }
                            </button>
                        </div>
                    </form>
                    <div className={styles['divider']}></div>
                </div>
                <Toast className={styles['toast']} error={this.state.isFormError} title={this.state.isFormError ? 'Error' : 'Thank you'} description={this.state.toastMessage} onExit={this.exitToast} ref={ref => this.toast = ref} />
            </section>
        )
    }
}

export default Subscribe;