import React from 'react'
import { Link } from 'react-router-dom'
import gsap from 'gsap'

import bootstrapService from '../../../../services/BootstrapService'

import CourseInfo from '../../../shared/CourseInfo/CourseInfo'
import ApprovalInfo from './components/ApprovalInfo/ApprovalInfo'
import ArrowButton from '../../../shared/Buttons/ArrowButton/ArrowButton'

import approvalImage from './images/approval.svg'
import dateImage from './images/calendar.svg'
import creditsImage from './images/clock.svg'
import deliveryImage from './images/delivery.svg'

import styles from './CourseApprovalCard.module.css'

class CourseApprovalCard extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.image = null;
        this.arrow = null;

        // This Binding
        this.animateLeftSideEnter = this.animateLeftSideEnter.bind(this);
        this.animateLeftSideLeave = this.animateLeftSideLeave.bind(this);
        this.animateRightSideEnter = this.animateRightSideEnter.bind(this);
        this.animateRightSideLeave = this.animateRightSideLeave.bind(this);
    }

    // Event Handlers
    animateLeftSideEnter() {
        gsap.to(this.image, {
            duration: 0.3,
            scale: 1.02,
        })
    }

    animateLeftSideLeave() {
        gsap.to(this.image, {
            duration: 0.3,
            scale: 1,
        })
    }

    animateRightSideEnter() {
        gsap.to(this.arrow, {
            duration: 0.3,
            scale: 1.05,
        })
    }

    animateRightSideLeave() {
        gsap.to(this.arrow, {
            duration: 0.3,
            scale: 1,
        })
    }

    // Component
    render() {
        const { category, header, body, imageUrl, features, approver, approvalCode, approvalExpires, url } = this.props.data;
        const courseId = features.find(f => f.name === "courseId").value
        const course = bootstrapService.getCourse(courseId);
        
        if (!course) return;

        const showReleaseDate = bootstrapService.getShowReleaseDate() && course.releaseDate;

        return (
            <div className={`${styles['content']} ${this.props.className}`} ref={this.props.innerRef}>
                <Link to={`/course/${features.find(f => f.name === "courseId").value}`}>
                    <div className={styles['course']} onMouseEnter={this.animateLeftSideEnter} onMouseLeave={this.animateLeftSideLeave}>
                        <p className={styles['course-category']}>{category}</p>
                        <h3 className={styles['course-title']}>{course.title}</h3>
                        <p className={styles['course-description']}>{course.description}</p>
                        <div className={styles['course-info']}>
                            {
                                showReleaseDate &&
                                <CourseInfo image={dateImage} property='Publish Date' value={new Date(course.releaseDate).toLocaleDateString("en-US", {month: 'short', year: 'numeric'})} />
                            }
                            {
                                !showReleaseDate &&
                                <CourseInfo image={deliveryImage} property='Delivery Method' value={course.deliveryMethod} />
                            }
                            <CourseInfo image={creditsImage} property={bootstrapService.getCreditsTerm(course.credits)} value={course.credits.toFixed(1)} />
                        </div>
                        <img src={imageUrl} alt='' className={styles['course-image']} ref={ref => this.image = ref} />
                    </div>
                </Link>
                <a href={url} target="_blank" rel="noopener noreferrer">
                    <div className={styles['approval']} onMouseEnter={this.animateRightSideEnter} onMouseLeave={this.animateRightSideLeave}>
                        <div className={styles['stamp-container']}>
                            <div className={styles['stamp-circle']}>
                                <img src={approvalImage} alt='' className={styles['stamp-image']} />
                            </div>
                            <span className={styles['stamp']}>Approved</span>
                        </div>
                        <h4 className={styles['approval-title']}>{approver}</h4>
                        <p className={styles['approval-description']} dangerouslySetInnerHTML={{ __html: body }}></p>
                        <div className={styles['approval-info']}>
                            <ApprovalInfo property='Provider ID' value={approvalCode}/>
                            <ApprovalInfo property='Expires' value={approvalExpires}/>
                        </div>
                        <ArrowButton className={styles['button']} ref={ref => this.arrow = ref} />
                    </div>
                </a>
            </div>
        )
    }
}

// Need to forward the ref to the compoent so that parent components can access the inner ref 
export default React.forwardRef((props, ref) => <CourseApprovalCard 
  innerRef={ref} {...props}
/>);